@charset "utf-8";

$serif      : 'Martel';
$sans-serif : 'Martel Sans';
$monospace  : 'Fira Code';

.collapsed {
  font-size: 0.875rem;
  max-height: 0px;
  overflow: hidden;
  text-align: justify;
  transition-property: 0.15s ease;
  -moz-transition: 0.15s ease;
  -ms-transition: 0.15s ease;
  -o-transition: 0.15s ease;
  transition: all 0.15s ease;

  p {
    line-height: 1.4em;
    margin: 10px;
  }
  pre {
    font-size: 1em;
    line-height: 1.4em;
    padding: 10px;
  }
}
.collapsed.open {
  max-height: 100em;
  transition-property: 0.15s ease;
  -moz-transition: 0.15s ease;
  -ms-transition: 0.15s ease;
  -o-transition: 0.15s ease;
  transition: all 0.15s ease;
}
div.abstract.collapsed {
  border: dashed 1px var(--global-bg-color);
}
div.abstract.collapsed.open {
  border-color: var(--global-text-color);
}

@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials
